import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import AuthService from 'services/AuthService';
import Cookies from 'js-cookie';

export const getAuthTokenFromCookies = () => {
	return Cookies.get(AUTH_TOKEN)
}

const storeAuthTokenInCookie = (token) => {
	Cookies.set(AUTH_TOKEN, token, {
		expires: 2, // days
		secure: true, // Only over HTTPS
		httpOnly: false, // true is not accessible/read by JavaScript, hence setting false by default
		sameSite: 'Strict' // Restrict cross-site access
	  });
}

export const removeAuthTokenFromCookie = () => {
	Cookies.remove(AUTH_TOKEN)
}

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: getAuthTokenFromCookies() || null,
	user: {}
}

export const signIn = createAsyncThunk('auth/login',async (data, { rejectWithValue }) => {
	const { username, password } = data
	try {
		const response = await AuthService.login({username, password})
		const token = response.data.token;
		storeAuthTokenInCookie(token)
		return token;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const signUp = createAsyncThunk('auth/register',async (data, { rejectWithValue }) => {
	const { email, password, name } = data
	try {
		const response = await AuthService.register({username: email, password, fullName: name})
		const token = response.data.token;
		storeAuthTokenInCookie(token)
		return token;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const signOut = createAsyncThunk('auth/logout',async () => {
	removeAuthTokenFromCookie();
    return
})

export const signInWithGoogle = createAsyncThunk('auth/signInWithGoogle', async (data, { rejectWithValue }) => {
	const { credential } = data
    try {
		const response = await AuthService.googleLogin({token: credential})
		const token = response.data.token;
		storeAuthTokenInCookie(token)
		return token;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const signInWithFacebook = createAsyncThunk('auth/signInWithFacebook', async (_, { rejectWithValue }) => {
    try {
		const response = await AuthService.loginInOAuth()
		const token = response.data.token;
		storeAuthTokenInCookie(token)
		return token;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getUser = createAsyncThunk('auth/user', async (_, { rejectWithValue }) => {
    try {
		const response = await AuthService.getUser()
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})


export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		authenticated: (state, action) => {
			state.loading = false
			state.redirect = '/'
			state.token = action.payload
		},
		showAuthMessage: (state, action) => {
			state.message = action.payload
			state.showMessage = true
			state.loading = false
		},
		hideAuthMessage: (state) => {
			state.message = ''
			state.showMessage = false
		},
		signOutSuccess: (state) => {
			state.loading = false
			state.token = null
			state.redirect = '/'
		},
		showLoading: (state) => {
			state.loading = true
		},
		signInSuccess: (state, action) => {
			state.loading = false
			state.token = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(signIn.pending, (state) => {
				state.loading = true
			})
			.addCase(signIn.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signIn.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signOut.fulfilled, (state) => {
				state.loading = false
				state.token = null
				state.redirect = '/'
			})
			.addCase(signOut.rejected, (state) => {
				state.loading = false
				state.token = null
				state.redirect = '/'
			})
			.addCase(signUp.pending, (state) => {
				state.loading = true
			})
			.addCase(signUp.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signUp.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signInWithGoogle.pending, (state) => {
				state.loading = true
			})
			.addCase(signInWithGoogle.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
				storeAuthTokenInCookie(state.token)
			})
			.addCase(signInWithGoogle.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signInWithFacebook.pending, (state) => {
				state.loading = true
			})
			.addCase(signInWithFacebook.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signInWithFacebook.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(getUser.pending, (state) => {
				state.status = 'loading'
			})
			.addCase(getUser.fulfilled, (state, action) => {
				state.status = 'success'
				state.user = action.payload
			})
			.addCase(getUser.rejected, (state, action) => {
				state.status = 'failure'
				state.error = action.error.message
			})
	},
})

export const { 
	authenticated,
	showAuthMessage,
	hideAuthMessage,
	signOutSuccess,
	showLoading,
	signInSuccess
} = authSlice.actions

export default authSlice.reducer

export const selectUser = state => state.auth.user
export const selectToken = state => state.auth.token
export const selectLoading = state => state.auth.loading
export const selectMessage = state => state.auth.message
export const selectShowMessage = state => state.auth.showMessage
export const selectRedirect = state => state.auth.redirect