import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import examService from "services/ExamService";

export const createExam = createAsyncThunk('exam/createExam', async (data, { rejectWithValue }) => {
    const { title, description, imgUrl } = data
	try {
		const response = await examService.createExam({ title, description, imgUrl})
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getDomainExams = createAsyncThunk('exam/getDomainExams', async ({domain}, { rejectWithValue }) => {
	try {
		const response = await examService.getExams(domain)
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getAllExams = createAsyncThunk('exam/getAllExams', async (_, { rejectWithValue }) => {
	try {
		const response = await examService.getAllExams()
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getAttemptedExams = createAsyncThunk('exam/getAttemptedExams', async (_, { rejectWithValue }) => {
	try {
		const response = await examService.getAttemptedExams()
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getExamSessions = createAsyncThunk('exam/getExamSessions', async (data, { rejectWithValue }) => {
    const { examId } = data
	try {
		const response = await examService.getExamSessions({examId})
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const createSubject = createAsyncThunk('exam/createSubject', async (data, { rejectWithValue }) => {
    const { examId, title } = data
	try {
		const response = await examService.createExamSubject({ title, examId})
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const createTopic = createAsyncThunk('exam/createTopic', async (data, { rejectWithValue }) => {
    const { examId, subjectId, title } = data
	try {
		const response = await examService.createExamTopic({ title, examId, subjectId})
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getSubjectQuestions = createAsyncThunk('subject/getQuestions', async (data, { rejectWithValue }) => {
    const { examId, subjectId, topicId } = data
	try {
		const response = await examService.getSubjectQuestions({ examId, subjectId, topicId})
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
    domainExams: {},
    allExams: {},
    attemptedExams: {},
    examSessions: {},
    subjectQuestions: {},
    status: 'idle',
    error: null
}

export const examSlice = createSlice({
    name: 'exam',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
        .addCase(createExam.pending, (state) => {
            state.status = 'loading'
        })
        .addCase(createExam.fulfilled, (state, action) => {
            state.status = 'success'
        })
        .addCase(createExam.rejected, (state, action) => {
            state.status = 'failure'
            state.error = action.error.message
        })
        .addCase(getDomainExams.pending, (state) => {
            state.status = 'loading'
        })
        .addCase(getDomainExams.fulfilled, (state, action) => {
            state.status = 'success'
            const key = action?.payload?.domain?.key;
            state.domainExams[key] = action.payload
        })
        .addCase(getDomainExams.rejected, (state, action) => {
            state.status = 'failure'
            state.error = action.error.message
        })
        .addCase(getAttemptedExams.fulfilled, (state, action) => {
            state.status = 'success'
            state.attemptedExams = action.payload
        })
        .addCase(getAttemptedExams.rejected, (state, action) => {
            state.status = 'failure'
            state.error = action.error.message
        })
        .addCase(getAllExams.fulfilled, (state, action) => {
            state.status = 'success'
            state.allExams = action.payload
        })
        .addCase(getAllExams.rejected, (state, action) => {
            state.status = 'failure'
            state.error = action.error.message
        })
        .addCase(getExamSessions.pending, (state) => {
            state.status = 'loading'
        })
        .addCase(getExamSessions.fulfilled, (state, action) => {
            state.status = 'success'
            state.examSessions = action.payload
        })
        .addCase(getExamSessions.rejected, (state, action) => {
            state.status = 'failure'
            state.error = action.error.message
        })
        .addCase(getSubjectQuestions.fulfilled, (state, action) => {
            state.status = 'success'
            state.subjectQuestions = action.payload
        })
        .addCase(getSubjectQuestions.rejected, (state, action) => {
            state.status = 'failure'
            state.error = action.error.message
        })
    }

})

export default examSlice.reducer

export const selectAllDomainExams = state => state.exam.domainExams
export const selectAllExams = state => state.exam.allExams
export const selectAttemptedExams = state => state.exam.attemptedExams
export const selectExamStatus = state => state.exam.status
export const selectExamById = (state, examId) => state.exam.exams.exams.find(exam => examId === exam.id)
export const selectSubjectQuestions = state => state.exam.subjectQuestions
export const selectExamSessions = (state) => {
    let sessions = state.exam.examSessions;
    return sessions
}
