import fetch from 'auth/FetchInterceptor'

const examService = {}
const domain = 'engineering'

examService.getExams = function (domain) {
  return fetch({
    url: `/domain/${domain}/exams`,
    method: 'get',
    params: {}
  })
}

examService.getAttemptedExams = function () {
  return fetch({
    url: `/attempt/exams`,
    method: 'get',
    params: {}
  })
}

examService.getAllExams = function () {
  return fetch({
    url: `/domain/exams`,
    method: 'get',
    params: {}
  })
}

examService.getSubjectQuestions = function({examId, subjectId, topicId}) {
  return fetch({
    url: `/subject/${examId}/${subjectId}/${topicId}/questions`,
    method: 'get',
    params: {}
  })
}

examService.createExam = function (data) {
    return fetch({
      url: `/exam/${domain}`,
      method: 'post',
      data
    })
}

examService.getExamSessions = function ({examId}) {
    return fetch({
        url: `/exam/${examId}/sessions`,
        method: 'get',
        params: {}
    })
}

examService.createExamSubject = function (data) {
  return fetch({
    url: `/subject/${data.examId}`,
    method: 'post',
    data
  })
}

examService.createExamTopic = function (data) {
  return fetch({
    url: `/subject/${data.examId}/${data.subjectId}`,
    method: 'post',
    data
  })
}

export default examService 