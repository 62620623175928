const dev = {
  	API_ENDPOINT_URL: 'http://localhost:8080/api/v1'
};

const beta = {
	API_ENDPOINT_URL: 'https://beta-api.witest.in/api/v1'
}

const prod = {
  	API_ENDPOINT_URL: 'https://api.witest.in/api/v1'
};

const test = {
  	API_ENDPOINT_URL: 'https://api.witest.in/api/v1'
};

const getDomain = () => {
	const origin = window.location.origin;
	if (origin.includes('http://localhost')) return 'dev'
	if (origin.includes('https://beta-app')) return 'beta'
	if (origin.includes('https://app')) return 'prod'
	return 'test'
}

const getEnv = () => {
	const domain = getDomain()
	switch (domain) {
		case 'dev':
			return dev
		case 'beta':
			return beta
		case 'prod':
			return prod
		case 'test':
			return test
		default:
			return test;
	}
}

export const env = getEnv()
