import fetch from 'auth/FetchInterceptor'

const attemptService = {}

attemptService.getQuestionPaper = function ({sessionId}) {
  return fetch({
    url: `/attempt/${sessionId}/paper`,
    method: 'get',
    params: {}
  })
}

attemptService.getExamAttempts = function ({examId}) {
  return fetch({
    url: `/attempt/exam/${examId}`,
    method: 'get',
    params: {}
  })
}

attemptService.getSessionAttempts = function ({sessionId}) {
  return fetch({
    url: `/attempt/${sessionId}`,
    method: 'get',
    params: {}
  })
}

attemptService.getExamAttemptsAnalysis = function ({examId}) {
  return fetch({
    url: `/attempt/exam/${examId}/analysis`,
    method: 'get',
    params: {}
  })
}

attemptService.getSessionAttemptsAnalysis = function ({sessionId}) {
  return fetch({
    url: `/attempt/${sessionId}/analysis`,
    method: 'get',
    params: {}
  })
}

attemptService.getSessionAttempt = function ({sessionId, attemptId}) {
  return fetch({
    url: `/attempt/${sessionId}/${attemptId}`,
    method: 'get',
    params: {}
  })
}

attemptService.createAttempt = function ({sessionId}) {
  return fetch({
    url: `/attempt/${sessionId}`,
    method: 'post',
    params: {}
  })
}

attemptService.createEvent = function (data) {
  return fetch({
    url: `/attempt/event`,
    method: 'post',
    data
  })
}

attemptService.submitAttempt = function ({sessionId, attemptId}) {
  return fetch({
    url: `/attempt/${sessionId}/${attemptId}`,
    method: 'post',
    params: {}
  })
}

export default attemptService