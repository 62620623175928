import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import sessionService from "services/SessionService";


export const createSession = createAsyncThunk('session/createSession', async (data, { rejectWithValue }) => {
    const { title, description, examId, date, questions, time, marks } = data
	try {
		const response = await sessionService.createSession({ title, description, examId, date, questions, time, marks})
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getSession = createAsyncThunk('session/getSession', async (data, { rejectWithValue }) => {
    const { examId, sessionId } = data
	try {
		const response = await sessionService.getSession({examId, sessionId})
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getSessionQuestions = createAsyncThunk('session/questions', async (data, { rejectWithValue }) => {
    
	const { sessionId } = data
	try {
		const response = await sessionService.getQuestions({sessionId})
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getSessionAnswers = createAsyncThunk('session/answers', async (data, { rejectWithValue }) => {
    
	const { sessionId } = data
	try {
		const response = await sessionService.getAnswers({sessionId})
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getSessionSections = createAsyncThunk('session/sections', async (data, { rejectWithValue }) => {
	const { sessionId } = data
	try {
		const response = await sessionService.getSections({sessionId})
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const createQuestion = createAsyncThunk('session/createQuestion', async (data, { rejectWithValue }) => {
    const { subjectKey, topicKey, difficulty, markingScheme, type,
        question, imgUrl, optionA, optionB, optionC,optionD,
        examId, sessionId, sectionKey, correct, incorrect } = data
	try {
		const response = await sessionService.postQuestion({subjectKey, topicKey, difficulty, markingScheme, type,
            question, imgUrl, options: [ 
                { text: optionA, sequence: 0},
                { text: optionB, sequence: 1},
                { text: optionC, sequence: 2},
                { text: optionD, sequence: 3}
            ],
            marking:{
                correct,
                incorrect
            },
            examId, sessionId, sectionKey})
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const createSection = createAsyncThunk('session/createSection', async (data, { rejectWithValue }) => {
    const { title, description, sequence, questionCount, maxAttempt, sessionId } = data
	try {
		const response = await sessionService.postSection({ title, description, sequence, questionCount, maxAttempt, sessionId })
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const initialState = {
    status: 'idle',
    error: null,
    sessionQuestions: {},
    sessionAnswers: {},
    sessionSections: {},
    session: {}
}

export const sessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
        .addCase(createSession.pending, (state) => {
            state.status = 'loading'
        })
        .addCase(createSession.fulfilled, (state, action) => {
            state.status = 'success'
        })
        .addCase(createSession.rejected, (state, action) => {
            state.status = 'failure'
            state.error = action.error.message
        })
        .addCase(getSession.pending, (state) => {
            state.status = 'loading'
        })
        .addCase(getSession.fulfilled, (state, action) => {
            state.status = 'success'
            state.session = action.payload
        })
        .addCase(getSession.rejected, (state, action) => {
            state.status = 'failure'
            state.error = action.error.message
        })
        .addCase(createQuestion.pending, (state) => {
            state.status = 'loading'
        })
        .addCase(createQuestion.fulfilled, (state, action) => {
            state.status = 'success'
        })
        .addCase(createQuestion.rejected, (state, action) => {
            state.status = 'failure'
            state.error = action.error.message
        })
        .addCase(getSessionQuestions.pending, (state) => {
            state.status = 'loading'
        })
        .addCase(getSessionQuestions.fulfilled, (state, action) => {
            state.status = 'success'
            state.sessionQuestions = action.payload
        })
        .addCase(getSessionQuestions.rejected, (state, action) => {
            state.status = 'failure'
            state.error = action.error.message
        })
        .addCase(getSessionAnswers.pending, (state) => {
            state.status = 'loading'
        })
        .addCase(getSessionAnswers.fulfilled, (state, action) => {
            state.status = 'success'
            state.sessionAnswers = action.payload
        })
        .addCase(getSessionAnswers.rejected, (state, action) => {
            state.status = 'failure'
            state.error = action.error.message
        })
        .addCase(createSection.pending, (state) => {
            state.status = 'loading'
        })
        .addCase(createSection.fulfilled, (state, action) => {
            state.status = 'success'
        })
        .addCase(createSection.rejected, (state, action) => {
            state.status = 'failure'
            state.error = action.error.message
        })
        .addCase(getSessionSections.pending, (state) => {
            state.status = 'loading'
        })
        .addCase(getSessionSections.fulfilled, (state, action) => {
            state.status = 'success'
            state.sessionSections = action.payload
        })
        .addCase(getSessionSections.rejected, (state, action) => {
            state.status = 'failure'
            state.error = action.error.message
        })
       
    }
})

export default sessionSlice.reducer

export const selectSession = state => state.session.session
export const selectSessionQuestions = state => state.session.sessionQuestions
export const selectSessionAnswers = state => state.session.sessionAnswers
export const selectSessionSections = state => state.session.sessionSections
export const selectSessionStatus = state => state.session.status